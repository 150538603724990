import moment from 'moment'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import i18n from '../i18n/config'
import {
  AddressReqType,
  AddressResType,
  CardType,
  ChannelType,
  ChatRoomMember,
  FileType,
  MeetingJoinStatus,
  MeetingJoinType,
  MemberType,
  ProjectMemberType,
  UserType,
} from '../models'

import { ListPage } from '@rocket/types'
import JSONBigInt from 'json-bigint'
import { CHANNEL_SIZE } from '../constructor'

import { LanguageResult, loadModule } from 'cld3-asm'
const JSONBig = JSONBigInt({ storeAsString: true })

const mySwal = withReactContent(Swal)

let _authToken: string
let currentUser: any
let _roomId: string
let _cardNo: string
let headers: any

export const getAuthToken = () => {
  return _authToken
}

export const fileSize = (x: number) => {
  if (x) {
    const s = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB']
    const e = Math.floor(Math.log(x) / Math.log(1024))
    return (x / Math.pow(1024, e)).toFixed(2) + ' ' + s[e]
  } else {
    return ''
  }
}

export const fileDownload = async (file: FileType) => {
  if (file && file.fileKey && file.filename) {
    await fetch(
      process.env.REACT_APP_URI_API_TALK +
        `/downloads/${file.fileKey}/${file.filename}`,
      { headers }
    )
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = file.originalFilename
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        mySwal.fire({
          title: i18n.t('board.card.download.complete'),
          showConfirmButton: false,
          timer: 1000,
          backdrop: false,
        })
      })
      .catch(() => {
        mySwal.fire({
          title: i18n.t(`board.card.download.fail`),
          backdrop: false,
        })
      })
  } else {
    mySwal.fire({
      title: i18n.t(`board.card.download.refresh`),
      backdrop: false,
    })
  }
}

export const fileDelete = async (missionNo: number, fileNo: number) => {
  return fetch(
    process.env.REACT_APP_URI_API_TALK +
      `/api/talk/missions/${missionNo}/files/${fileNo}`,
    { method: 'DELETE', headers }
  ).then((res) => res.json())
}

export const getToken = async (token: string) => {
  headers = { 'X-AUTH-TOKEN': token }
  const authToken = await fetch(
    process.env.REACT_APP_URI_API_TALK +
      `/api/talk/token?info=userToken&token=${token}`,
    { headers }
  ).then((res) => {
    if (res.status === 200) {
      return res.text()
    }
    return null
  })

  if (!authToken) return false
  return authToken
}

export const getRoomId = async (token: string) => {
  headers = { 'X-AUTH-TOKEN': token }
  const roomId = await fetch(
    process.env.REACT_APP_URI_API_TALK +
      `/api/talk/token?info=roomId&token=${token}`,
    { headers }
  ).then((res) => {
    if (res.status === 200) {
      return res.text()
    }
    return null
  })

  if (!roomId) throw new Error('cannot find roomId')

  return roomId
}

export const getMessageId = async (token: string) => {
  headers = { 'X-AUTH-TOKEN': token }
  const roomId = await fetch(
    process.env.REACT_APP_URI_API_TALK +
      `/api/talk/token?info=messageId&token=${token}`,
    { headers }
  ).then((res) => {
    if (res.status === 200) {
      return res.text()
    }
    return null
  })

  if (!roomId) return false
  return roomId
}

export const getLanguage = async (token: string) => {
  headers = { 'X-AUTH-TOKEN': token }
  const language = await fetch(
    process.env.REACT_APP_URI_API_TALK +
      `/api/talk/token?info=language&token=${token}`,
    { headers }
  ).then((res) => {
    if (res.status === 200) {
      return res.text()
    }
    return null
  })

  if (!language) return false
  return language
}

export const getCardNo = async (token: string) => {
  headers = { 'X-AUTH-TOKEN': token }
  const cardNo = await fetch(
    process.env.REACT_APP_URI_API_TALK +
      `/api/talk/token?info=cardNo&token=${token}`,
    { headers }
  ).then((res) => {
    if (res.status === 200) {
      return res.text()
    }
    return null
  })

  if (!cardNo) return false
  return cardNo
}

export const setToken = (
  token: string,
  data: { sessionRoomId: string; sessionCardNo?: string }
) => {
  _authToken = token
  _roomId = data.sessionRoomId
  if (data.sessionCardNo) _cardNo = data.sessionCardNo
  headers = { 'X-AUTH-TOKEN': _authToken }
  return token
}

export const setCurrentUser = async (token: string): Promise<UserType> => {
  headers = { 'X-AUTH-TOKEN': token }
  currentUser = await fetch(process.env.REACT_APP_URI_API_APP + '/me', {
    headers,
  }).then((res) => {
    if (res.status === 200) {
      return res.json()
    }
    return null
  })
  return currentUser
}

export const getCurrentUser = () => {
  // console.log('currentUser', currentUser)
  return currentUser
}

/**
 * @deprecated Use Astronaut SDK instead
 * @param cardNo
 * @returns
 */
export const deleteChannelDiscussion = async (
  channelId: string,
  discussionId: string
) => {
  return fetch(
    process.env.REACT_APP_URI_API_TALK +
      `/api/talk/chats/${channelId}/discussions/${discussionId}/members/leave`,
    { method: 'DELETE', headers }
  ).then((res) => {
    if (res.status === 200) return res.text()
    else throw new Error('cannot leave discussion: ' + discussionId)
  })
}

/**
 * @deprecated Use Astronaut SDK instead
 * @param cardNo
 * @returns
 */
export const getChannelDiscussion = async (
  channelId: string,
  discussionId: string
) => {
  return fetch(
    process.env.REACT_APP_URI_API_TALK +
      `/api/talk/chats/${channelId}/discussions/${discussionId}`,
    { headers }
  ).then((res) => {
    if (res.status === 200) return res.json()
    return null
  })
}

/**
 * @deprecated Use Astronaut SDK instead
 * @param cardNo
 * @returns
 */
export const getChannelDiscussionByCardNo = async (
  channelId: string,
  cardNo: number
) => {
  const response = await fetch(
    process.env.REACT_APP_URI_API_TALK +
      `/api/talk/chats/${channelId}/discussions?cardNo=${cardNo}`,
    { headers }
  )
    .then((res) => {
      if (res.status === 200) return res.json()
      return null
    })
    .then((page) => {
      if (page) return page.content

      return page
    })

  return response
}

export const getCardByCardNo = async (cardNo: number) => {
  const response = await fetch(
    process.env.REACT_APP_URI_API_TALK + `/api/talk/card?cardNo=${cardNo}`,
    { headers }
  ).then((res) => {
    if (res.status === 200) return res.json()
    return null
  })

  return response
}

export const getCard = async () => {
  const response = await fetch(
    process.env.REACT_APP_URI_API_TALK + `/api/talk/card/${_cardNo}`,
    { headers }
  ).then((res) => {
    const permissionToken = res.headers.get('Card-Page-Permission-Token')
    if (res.status === 200)
      return res.json().then((json) => ({ ...json, permissionToken }))
    else return null
  })

  console.log('getCard', response)
  return response
}

export const getCardMission = async (channelId: string) => {
  const response = await fetch(
    process.env.REACT_APP_URI_API_TALK +
      `/api/talk/card/${_cardNo}/mission?channelId=${channelId}`,
    { headers }
  ).then((res) => {
    if (res.status === 200) {
      return res.json()
    }
    return null
  })
  console.log('getCardMission', response)
  //messageList.push(response);
  return response
}

export const sendMessageMail = async (messageNo: string, userNo: number) => {
  const body = new FormData()
  body.append('messageNo', messageNo + '')
  body.append('userNo', userNo + '')
  const response = await fetch(
    process.env.REACT_APP_URI_API_TALK + `/api/talk/send/mail`,
    { method: 'POST', headers, body }
  ).then((res) => {
    if (res.status === 200) {
      return res.json()
    }
    return null
  })
  console.log('sendMessageMail', response)
  return response
}
export const sendMessagePush = async (messageNo: string, userNo: number) => {
  const body = new FormData()
  body.append('messageNo', messageNo)
  body.append('userNo', userNo + '')
  const response = await fetch(
    process.env.REACT_APP_URI_API_TALK + `/api/talk/send/push`,
    { method: 'POST', headers, body }
  ).then((res) => {
    if (res.status === 200) {
      return res.json()
    }
    return null
  })
  console.log('sendMessageMail', response)
  return response
}

export const getChannel = async (
  channelRoomId?: string
): Promise<ChannelType> => {
  const response = await fetch(
    process.env.REACT_APP_URI_API_TALK +
      `/api/talk/chats/${channelRoomId ? channelRoomId : _roomId}`,
    { headers }
  )
    .then((res) => {
      if (res.status === 200) return res.text()
      return null
    })
    .then((data) => {
      if (!data) return null
      return JSONBig.parse(data)
    })

  if (response) {
    const memberList = await getMemberList()
    response.members = memberList
    response.owner = memberList.find(
      (o: MemberType) => o.member?.no === currentUser.no && o.owner
    )
      ? true
      : false
  }
  return response
}

export const uploadFile = async ({
  channelRoomId,
  file,
  recipientNo,
  messageKey,
  onPercent,
}: {
  channelRoomId: string
  file: File
  messageKey?: string
  recipientNo?: string
  onPercent?: (percent: number) => void
}) => {
  const url =
    process.env.REACT_APP_URI_API_TALK + `/api/talk/chats/${channelRoomId}/file`
  const formData = new FormData()
  formData.append('file', file)
  if (recipientNo) formData.append('recipientNo', recipientNo)
  if (messageKey) formData.append('messageKey', messageKey)

  return new Promise((resolve, reject) => {
    if (file.size >= 52428800) {
      reject('noupload')
    } else {
      const xhr = new XMLHttpRequest()
      xhr.onreadystatechange = () => {
        console.log('uploadfile-state', xhr.readyState, xhr.status)
        if (xhr.readyState === 4 && xhr.status === 200) {
          resolve(xhr.response)
        } else if (xhr.readyState === 4 && xhr.status === 413) {
          reject('noupload')
        } else if (xhr.readyState === 4 && xhr.status !== 200) {
          reject('disconnection')
        }
      }
      xhr.upload.onprogress = (e) => {
        const percent = Math.floor((e.loaded / e.total) * 100)
        onPercent && onPercent(percent)
      }
      xhr.open('post', url)
      xhr.setRequestHeader('X-AUTH-TOKEN', _authToken)
      xhr.send(formData)
    }
  })
}

export const getProject = async (projectNo: number) => {
  const response = await fetch(
    process.env.REACT_APP_URI_API_PROJECT + `/api/project/${projectNo}`,
    { headers }
  )
    .then((res) => {
      if (res.status === 200) return res.json()
      return null
    })
    .then((data) => {
      if (data) {
        return data
      } else {
        return null
      }
    })
  console.log('getProject', response)
  return response
}

export const getProjectMembers = async (projectNo: number) => {
  const response = await fetch(
    process.env.REACT_APP_URI_API_PROJECT + `/api/project/${projectNo}/members`,
    { headers }
  )
    .then((res) => {
      if (res.status === 200) return res.json()
      return null
    })
    .then((data) => {
      if (data) {
        return data
      } else {
        return null
      }
    })
  console.log('getProjectMember', response)
  return response
}

export const getProjectMember = async (projectNo: number, userNo: number) => {
  const response = await fetch(
    process.env.REACT_APP_URI_API_PROJECT + `/api/project/${projectNo}/members`,
    { headers }
  )
    .then((res) => {
      if (res.status === 200) return res.json()
      return null
    })
    .then((data) => {
      if (data) {
        return data.find((o: ProjectMemberType) => o.userNo === userNo)
      } else {
        return null
      }
    })
  console.log('getProjectMember', response)
  return response
}

export const getMemberList = async (
  channelRoomId?: string
): Promise<MemberType[]> => {
  const response = await fetch(
    process.env.REACT_APP_URI_API_TALK +
      `/api/talk/chats/${channelRoomId ? channelRoomId : _roomId}/allmembers`,
    { headers }
  ).then((res) => {
    if (res.status === 200) return res.json()
    return null
  })
  console.log('getMemberList', response)
  //messageList.push(response);
  return response
}

export const getMemberListPage = async (
  channelRoomId?: string,
  page: number = 0,
  size: number = 20
): Promise<ListPage<ChatRoomMember>> => {
  const response = await fetch(
    process.env.REACT_APP_URI_API_TALK +
      `/api/talk/chats/${
        channelRoomId ? channelRoomId : _roomId
      }/allmembers/paging?page=${page}&size=${size}&sort=manager,desc&sort=no,asc`,
    { headers }
  ).then((res) => {
    if (res.status === 200) return res.json()
    return null
  })
  // console.log('getMemberList', response)
  //messageList.push(response);
  return response
}

const getLang = (data: string): Promise<LanguageResult | undefined> => {
  return new Promise((resolve) => {
    const message = data
      .replace(/<[^>]*>?/g, '')
      .replace(
        /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g,
        ''
      )
      .replace(/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/g, '')
      .trim()
    const length = message.length
    // if (length < 2) return o

    loadModule()
      .then((cldFactory) => {
        const identifier = cldFactory.create(0, 1024)
        const lang1 = identifier.findLanguage(message)
        const lang2 = identifier.findMostFrequentLanguages(message, 3)

        if (lang2.length > 0) {
          const lang = lang2[0]

          console.log(
            'findMostFrequentLanguages',
            lang,
            lang1,
            lang2,
            { message, length },
            data
          )

          if (lang.probability < 0.5) resolve(undefined)
          resolve(lang)
        }

        resolve(undefined)
      })
      .catch(() => {
        resolve(undefined)
      })
  })
}

export const getMessage = async (messageNo: string) => {
  const response = await fetch(
    process.env.REACT_APP_URI_API_TALK +
      `/api/talk/${_roomId}/messages/${messageNo}`,
    { headers }
  )
    .then((res) => {
      if (res.status === 200) return res.text()
      return null
    })
    .then((data) => {
      if (!data) return null
      return JSONBig.parse(data)
    })
    .then(async (data) => {
      if (data) {
        if (data.type === 'msg') {
          const lang = await getLang(data.message)
          // if (lang2.length > 1) return o
          if (lang === undefined) return data

          return {
            ...data,
            lang: lang.language,
          }
        } else return data
      } else return data
    })
  return response
}

export const getLastMessage = async (channelId: string) => {
  const response = await fetch(
    process.env.REACT_APP_URI_API_TALK + `/api/talk/${channelId}/messages/last`,
    { headers }
  )
    .then((res) => {
      if (res.status === 200) return res.text()
      return null
    })
    .then((data) => {
      if (!data) return null
      return JSONBig.parse(data)
    })
  return response
}

export const getUnreadCount = async (channelId: string): Promise<number> => {
  const response = await fetch(
    process.env.REACT_APP_URI_API_TALK +
      `/api/talk/${channelId}/messages/unreadCount`,
    { headers }
  ).then((res) => {
    if (res.status === 200) return res.json()
    return null
  })
  return response
}

export const updateMessage = async (
  channelRoomId: string,
  messageNo: string,
  message: string
) => {
  const body = new FormData()
  body.append('message', message)

  console.log('updateMessage', messageNo, message)
  const response = await fetch(
    process.env.REACT_APP_URI_API_TALK +
      `/api/talk/${channelRoomId}/messages/${messageNo}`,
    { method: 'PUT', headers, body }
  ).then((res) => res)
  return response
}

export const deleteMessage = async (
  channelRoomId: string,
  messageNo: string,
  isForce: boolean = false
) => {
  const body = new FormData()
  body.append('isForce', isForce ? 'true' : 'false')
  const response = await fetch(
    process.env.REACT_APP_URI_API_TALK +
      `/api/talk/${channelRoomId}/messages/${messageNo}`,
    { method: 'DELETE', headers, body }
  ).then((res) => res)
  return response
}

export const readMessage = async (channelRoomId: string) => {
  if (!channelRoomId) return null
  const response = await fetch(
    process.env.REACT_APP_URI_API_TALK +
      `/api/talk/${channelRoomId}/messages/read`,
    { method: 'POST', headers }
  ).then((res) => {
    console.log('res', res)
    return res
  })
  return response
}

export const getUnreadMessage = async () => {
  const response = await fetch(
    process.env.REACT_APP_URI_API_TALK + `/api/talk/${_roomId}/messages/unread`,
    { headers }
  )
    .then((res) => {
      if (res.status === 200) {
        return res.text()
      }
      return null
    })
    .then((data) => {
      if (data) return JSONBig.parse(data)
      else return data
    })
  console.log('getUnreadMessage', response)
  return response
}

export const getMissionTodos = async (todoNo: any) => {
  const response = await fetch(
    process.env.REACT_APP_URI_API_TALK + `/api/talk/todos/${todoNo}/mission`,
    { headers }
  ).then((res) => {
    if (res.status === 200) return res.json()
    return null
  })
  console.log('getMissionTodo', response)
  return response
}

export const todoUploadFile = async (
  todoNo: any,
  isDone: any,
  uploadFile: File
) => {
  const formData = new FormData()
  formData.append('isDone', isDone)
  formData.append('uploadFile', uploadFile)

  return fetch(
    process.env.REACT_APP_URI_API_TALK + `/api/talk/todos/${todoNo}/files`,
    { method: 'POST', body: formData, headers }
  ).then((res) => {
    return res.json()
  })
}

export const setTodoStatus = async (todoNo: any, isDone: any) => {
  const url = process.env.REACT_APP_URI_API_TALK + `/api/talk/todos/${todoNo}`
  const method = 'POST'
  const body = new FormData()
  body.append('isDone', isDone)
  const response = await fetch(url, { method, headers, body }).then((res) => {
    if (res.status === 200) return res.json()
    return null
  })
  console.log('setTodoStatus', response)
  return response
}

export const fetchMessageList = async (channelRoomId: string, page: number) => {
  const response = await fetch(
    process.env.REACT_APP_URI_API_TALK +
      `/api/talk/${channelRoomId}/messages?page=${page}&size=${CHANNEL_SIZE}`,
    { headers }
  )
    .then((res) => res.text())
    .then((data) => {
      return JSONBig.parse(data)
    })
    .then(async (res) => {
      if (res.content) {
        const content = await Promise.all(
          res.content.map(async (data) => {
            if (data.type === 'msg') {
              const lang = await getLang(data.message)
              console.log('lang', lang)
              if (lang === undefined) return data

              return {
                ...data,
                lang: lang.language,
              }
            } else return data
          })
        )
        return { ...res, content }
      } else return res
    })
  return response
}

export const sendMessageData = async (channelRoomId: string, data: any) => {
  const formData = new FormData()
  Object.keys(data).map((key) =>
    formData.append(
      key,
      typeof data[key] !== 'object' ? data[key] : JSON.stringify(data[key])
    )
  )
  return fetch(
    process.env.REACT_APP_URI_API_TALK + `/api/talk/${channelRoomId}/messages`,
    { method: 'POST', body: formData, headers }
  )
    .then((res) => res.text())
    .then((res) => JSONBig.parse(res))
}

export const readCard = async (missionCardNo: number) => {
  const url =
    process.env.REACT_APP_URI_API_TALK +
    `/api/talk/missions/${missionCardNo}/read`
  const method = 'POST'
  const response = await fetch(url, { method, headers }).then((res) => {
    if (res.status === 200) return res.json()
    return null
  })
  console.log('readCard', response)
  return response
}

export const deleteCard = async (
  token: string,
  cardNo: string,
  writerNo?: number
): Promise<void | string | number> => {
  headers = { 'X-AUTH-TOKEN': token }

  currentUser = await fetch(process.env.REACT_APP_URI_API_APP + '/me', {
    headers,
  }).then((res) => res.json())

  const body = new FormData()
  body.append('userNo', currentUser.no)
  if (writerNo) {
    body.append('writerNo', String(writerNo))
  }

  return await fetch(
    process.env.REACT_APP_URI_API_TALK + `/api/talk/notices/${cardNo}`,
    {
      method: 'DELETE',
      body,
      headers,
    }
  )
    .then((res) => {
      if (res.status === 401) return 401
    })
    .catch((e: Error) => e.message)
}

export const updateCard = async (token: string, card: CardType) => {
  headers = { 'X-AUTH-TOKEN': token }

  const body = new FormData()
  body.append('noticeNo', card.no)
  body.append('title', card.title)
  body.append('content', card.content)
  body.append('isPublic', String(card.isPublic))
  body.append(
    'noticeDate',
    card.noticeDate ? moment(card.noticeDate).format() : ''
  )
  body.append(
    'sendDateEnd',
    card.noticeDate ? moment(card.noticeDate).format() : ''
  )
  body.append('roomIds', card.roomIds || '')
  if (!!card.teamTodos && card.teamTodos.length > 0) {
    body.append('teamTodos', JSON.stringify(card.teamTodos))
  }
  if (!!card.pickTodos && card.pickTodos.length > 0) {
    body.append('pickTodos', JSON.stringify(card.pickTodos))
  }
  if (card.files.length > 0) {
    card.files.forEach((file) => {
      if ('name' in file) {
        body.append('files', file, file.name)
      }
    })
  }
  if (card.deletedTodoIds) {
    body.append('deletedTodoIds', card.deletedTodoIds)
  }

  if (card.deletedFileIds) {
    body.append('deletedFileIds', card.deletedFileIds)
  }

  const response = await fetch(
    process.env.REACT_APP_URI_API_TALK + `/api/talk/notices/missions`,
    {
      method: 'POST',
      body,
      headers,
    }
  )
    .then((res) => {
      if (res.status === 200) return res.json()
      return null
    })
    .catch((e: Error) => console.error(e))
  return response
}

export const updateCardMeeting = async (token: string, card: CardType) => {
  headers = { 'X-AUTH-TOKEN': token }

  console.log('memberIds', card.memberIds)
  console.log('deletedMemberIds', card.deletedMemberIds)
  console.log('deletedFileIds', card.deletedFileIds)

  const body = new FormData()
  body.append('noticeNo', card.no)
  body.append('title', card.title)
  body.append('content', card.content)
  body.append('isPublic', card.isPublic ? String(card.isPublic) : 'false')
  body.append('isAttend', card.isAttend ? String(card.isAttend) : 'false')
  body.append(
    'noticeDate',
    card.noticeDate ? moment(card.noticeDate).format() : ''
  )
  // body.append('sendDate', card.sendDate ? moment(card.sendDate).format() : '')
  body.append('roomIds', card.roomIds || '')
  if (card.memberIds) {
    body.append('memberIds', card.memberIds)
  }
  if (card.deletedMemberIds) {
    body.append('deletedMemberIds', card.deletedMemberIds)
  }

  if (card.deletedFileIds) {
    body.append('deletedFileIds', card.deletedFileIds)
  }

  if (card.files.length > 0) {
    card.files.forEach((file) => {
      if ('name' in file) {
        body.append('files', file, file.name)
      }
    })
  }
  body.append('location', card.location || '')
  body.append('locationEtc', card.locationEtc || '')
  body.append('locationX', String(card.locationX || ''))
  body.append('locationY', String(card.locationY || ''))

  const response = await fetch(
    process.env.REACT_APP_URI_API_TALK + `/api/talk/notices/meetings`,
    {
      method: 'POST',
      body,
      headers,
    }
  )
    .then((res) => {
      if (res.status === 200) return res.json()
      return null
    })
    .catch((e: Error) => console.error(e))
  return response
}

export const getLocationForAddress = (address: string) => {
  return fetch(
    process.env.REACT_APP_URI_API_APP + `/geocoder?query=${address}`,
    { headers }
  )
    .then((res) => {
      if (res.status === 200) {
        return res.json()
      }
      return null
    })
    .catch((e) => console.error(e))
}

export const searchAddressList = async (req: AddressReqType) => {
  const header = {
    Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_API_KEY}`,
  }
  let body = `?query=${req.query}`
  if (req.analyze_type) {
    body += `&analyze_type=${req.analyze_type}`
  }
  if (req.page) {
    body += `&page=${req.page}`
  }
  if (req.size) {
    body += `&size=${req.size}`
  }

  // 주소 검색
  return await fetch(
    `https://dapi.kakao.com/v2/local/search/address.json${body}`,
    {
      headers: header,
    }
  ).then(async (res1) => {
    if (res1.status === 200) {
      const result1: AddressResType = await res1.json()

      // 만일 결과가 없으면 장소명(검색어) 검색
      if (result1.meta.total_count === 0) {
        body = body.replace(`&analyze_type=${req.analyze_type}`, '')

        return await fetch(
          `https://dapi.kakao.com/v2/local/search/keyword.json${body}`,
          { headers: header }
        ).then(async (res2) => {
          if (res2.status === 200) {
            const result2: AddressResType = await res2.json()

            if (result2.meta.total_count === 0) {
              return null
            } else {
              return result2
            }
          }
        })
      } else {
        return result1
      }
    }
  })
}

export const getMeetingJoinList = async (
  token: string,
  missionNo: number
): Promise<null | MeetingJoinType[]> => {
  headers = { 'X-AUTH-TOKEN': token }

  let result: null | MeetingJoinType[] = null

  await fetch(
    process.env.REACT_APP_URI_API_TALK +
      `/api/talk/missions/${String(missionNo)}/join`,
    { headers }
  )
    .then((res) => {
      if (res.status === 200) {
        result = res.json() as unknown as MeetingJoinType[]
      }
    })
    .catch((e) => console.error(e))

  return result
}

export const setMeetingJoinStatus = async (
  token: string,
  missionNo: number,
  joinStatus?: MeetingJoinStatus,
  joinEtc?: string
) => {
  headers = { 'X-AUTH-TOKEN': token }

  console.log('joinStatus', joinStatus)
  console.log('joinEtc', joinEtc)

  const body = new FormData()
  body.append('joinStatus', joinStatus || '')
  body.append('joinEtc', joinEtc || '')

  const response = await fetch(
    process.env.REACT_APP_URI_API_TALK +
      `/api/talk/missions/${String(missionNo)}/join`,
    {
      method: 'POST',
      body,
      headers,
    }
  )
    .then((res) => {
      if (res.status === 201 || res.status === 200) return res.json()
      return null
    })
    .catch((e: Error) => console.error(e))
  return response
}

export const getTerms = async (termsType: string) => {
  const response = await fetch(
    process.env.REACT_APP_URI_API_APP + `/api/terms/${termsType}`,
    { headers }
  ).then((res) => {
    if (res.status === 200) return res.json()
    return null
  })
  return response
}

export const getFileList = async (channelId: string, page = 0, size = 20) => {
  const response = await fetch(
    process.env.REACT_APP_URI_API_TALK +
      `/api/talk/files?roomId=${channelId}&page=${page}&size=${size}`,
    { headers }
  ).then((res) => {
    if (res.status === 200) return res.json()
    return null
  })
  return response
}
