import React, { FC } from 'react'
import AstroContext from './AstroContext'

import { Astro } from '@rocket/astronaut'
import { AstroOption } from '@rocket/types'

interface Props {
  token: string
  option: AstroOption
  children: JSX.Element
}

const AstroProvider: FC<Props> = ({ token, option, children }) => {
  const astro = new Astro(option)
  astro.token(token)

  return (
    <AstroContext.Provider value={{ astro }}>{children}</AstroContext.Provider>
  )
}

export default AstroProvider
