import { FileType } from './etc'
import { MissionType } from './todo'
import { UserType } from './user'

export enum CardClassification {
  ATTEND = 'ATTEND',
  MISSION = 'MISSION',
}

export enum MeetingJoinStatus {
  ATTEND = 'Y',
  LATE = 'R',
  NO_ATTEND = 'N',
  NO_RESPONSE = 'X',
}

export enum MeetingJoinLabel {
  Y = 'board.card.going',
  R = 'board.card.late',
  N = 'board.card.notgoing',
  X = 'board.card.noanswer',
}

export type CardShare = {
  no: number
  shortUrlPath: string
  type: string
}

export type CardType = {
  no: string
  title: string
  userNo: string
  content: string
  contentFiles?: Array<FileType | File>
  roomIds: string
  teamTodos?: MissionType[]
  pickTodos?: MissionType[]
  deletedTodoIds?: string
  deletedFileIds?: string
  noticeDate: null | Date
  sendDate?: Date
  location?: string
  locationEtc?: string
  locationX?: number
  locationY?: number
  regDate?: string
  modDate?: string
  files: Array<FileType | File>
  todos?: MissionType[]
  type?: CardClassification
  user?: UserType
  sendDateEnd?: Date
  isPublic: boolean
  isAttend?: boolean
  memberIds?: string
  deletedMemberIds?: string
  cardShare?: CardShare
  permissionToken?: string
}

export type MeetingJoinType = {
  user: UserType
  memberName?: string
  joinStatus?: MeetingJoinStatus
  joinEtc?: string
}
