import * as api from '../../apis'
import { CardType, ChannelType, MissionType, UserType } from '../../models'

export async function getCardDataStates(
  currentUser: UserType,
  channelData: ChannelType
) {
  const cardData: CardType | null = await api.getCard()

  const todos: MissionType[] = []

  if (!cardData) return null

  if (cardData.todos) {
    let order = 1
    for (const todo of cardData.todos) {
      let donePercent = 0
      if (todo.countDone && todo.countTodo)
        donePercent = (todo.countDone / todo.countTodo) * 100
      todos.push({
        ...todo,
        id: order - 1,
        order,
        donePercent: ~~donePercent,
      })
      order++
    }
  }
  return {
    ...cardData,
    todos,
    userNo: String(currentUser.no),
    noticeDate: cardData.noticeDate,
  }
}
