import * as React from 'react'
import jwt_decode from 'jwt-decode'

import { AuthContext, CardTokenType } from './AuthContext'

interface Props {
  children: JSX.Element
  token?: string
}

export const AuthProvider: React.FC<Props> = ({ children, token }) => {
  const [cardToken, setCardToken] = React.useState<CardTokenType>({
    userNo: null,
    cardNo: null,
    permission: null,
  })
  React.useEffect(() => {
    if (token) {
      const decode: CardTokenType = jwt_decode(token)
      setCardToken(decode)
    }
  }, [token])

  return (
    <AuthContext.Provider value={{ cardToken }}>
      {children}
    </AuthContext.Provider>
  )
}
