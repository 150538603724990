import { useContext } from 'react'
import { AuthContext, AuthProviderContext } from './AuthContext'

export function useCardToken() {
  const context = useContext<AuthProviderContext | undefined>(AuthContext)

  if (context === undefined)
    throw new Error(
      'There must be a AuthProvider as Ancestor of all Auth Hooks and HOCs'
    )

  return context.cardToken
}
