import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import GridlyBackend from 'i18next-gridly-backend'
import {
  LangType,
  SUPPORTED_LANGS,
  options,
  updateMomentLocale,
} from './options'

const i18nInstance = i18n.createInstance()

i18nInstance.use(initReactI18next)

const isProduction = process.env.NODE_ENV === 'production'

if (isProduction) {
  SUPPORTED_LANGS.forEach((lang) => {
    void import(`../../imported/locales/${lang}.json`).then((data) => {
      i18nInstance.addResourceBundle(lang, 'translation', data, true, true)
    })
  })
} else {
  i18nInstance.use(GridlyBackend)
}

i18nInstance.init(options, () => {
  i18nInstance.on('languageChanged', (lng) => {
    console.log('languageChanged', lng)
    updateMomentLocale(lng as LangType)
  })
})

export default i18nInstance
