import { DropdownGroupItem, MemberListItem } from '@rocket/components'
import {
  ChannelDiscussion,
  ChannelMember,
  Discussion,
  Legacy,
  Project,
  ProjectMember,
} from '@rocket/types'
import { atom, atomFamily, selectorFamily } from 'recoil'
import {
  DiscussionItem,
  FileType,
  MemberType,
  Message,
  MessageState,
  Page,
  ProjectMemberType,
} from '../../models'

export const loadingState = atom({
  key: 'loadingState',
  default: false,
})

export const showChatSkeletonState = atom({
  key: 'showChatSkeleton',
  default: true,
})

export const isBottomState = atomFamily<boolean, string>({
  key: 'isBottomState',
  default: true,
})

export const isTalkInputFocusState = atom({
  key: 'isTalkInputFocusState',
  default: false,
})

export const typingState = atomFamily<any[], string>({
  key: 'typingState',
  default: [],
})

export const messageListState = atomFamily<Message[], string>({
  key: 'messageListState',
  default: [],
})

export const messageLoadState = atomFamily<MessageState[], string>({
  key: 'messageLoadState',
  default: [],
})

export const messagePageState = atomFamily<Page<Message> | null, string>({
  key: 'messagePageState',
  default: null,
})

export const unreadCountState = atomFamily<number, string>({
  key: 'unreadCountState',
  default: 0,
})

export const unreadMessageState = atom<Message | null>({
  key: 'unreadMessageState',
  default: null,
})

export const currentMessageState = atom<{
  channelRoomId: string
  currentMessage: Message
} | null>({
  key: 'currentMessageState',
  default: null,
})

export const currentProjectState = atom<Project | null>({
  key: 'currentProjectState',
  default: null,
})

export const projectMemberState = atom<ProjectMemberType | null>({
  key: 'projectMemberState',
  default: null,
})

export const channelMemberState = atom<MemberType[]>({
  key: 'channelMemberState',
  default: [] as MemberType[],
})

export const channelMemberListState = atomFamily<ChannelMember[], string>({
  key: 'channelMemberListState',
  default: [],
})

export const currentMemberSelector = selectorFamily<
  MemberType | undefined,
  number
>({
  key: 'channelListByGroupNoSelector',
  get:
    (userNo) =>
    ({ get }) => {
      const list = get(channelMemberState)
      const member = list.find((o) => o.member?.no === userNo)
      return member
    },
})

export const previewImageState = atom<FileType | null>({
  key: 'previewImageState',
  default: null,
})

export const previewPdfState = atom<FileType | null>({
  key: 'previewPdfState',
  default: null,
})

export const updateMessageState = atomFamily<Message | null, string>({
  key: 'updateMessageState',
  default: null,
})

export const replyMessageState = atomFamily<Message | null, string>({
  key: 'replyMessageState',
  default: null,
})

export const recipientState = atomFamily<MemberType | null, string>({
  key: 'recipientState',
  default: null,
})

export const cardDeletedState = atom({
  key: 'cardDeletedState',
  default: false,
})

export const cardDiscussionListState = atom<Discussion[]>({
  key: 'cardDiscussionListState',
  default: [],
})

export const cardDiscussionState = atom<DiscussionItem>({
  key: 'cardDiscussionState',
  default: {
    no: 0,
    closed: false,
    isNotice: false,
    roomId: '',
    roomName: 'string',
    roomType: 'G',
    cardType: undefined,
    profileUrl: undefined,
  },
})

export const cardDiscussionPageState = atom({
  key: 'cardDiscussionPageState',
  default: {
    page: 0,
    size: 2000,
  },
})

export const headerTypeState = atom<'normal' | 'discussion'>({
  key: 'headerTypeState',
  default: 'normal',
})

export const discussionSelectedIndexState = atom({
  key: 'discussionSelectedIndexState',
  default: -1,
})

export const discussionSelectedReplyIndexState = atom<string | undefined>({
  key: 'discussionSelectedIndexState',
  default: undefined,
})

export const projectNameState = atom<string>({
  key: 'projectNameState',
  default: '',
})

export const headerSettingState = atom({
  key: 'headerSettingState',
  default: {
    type: 'ORG',
    color: 'mono.brightBlue',
  },
})

export const cardShareModalVisibleState = atom({
  key: 'cardShareModalVisibleState',
  default: false,
})

export const termsState = atom({
  key: 'termsState',
  default: {
    termsTitle: '',
    termsContents: '',
    privacyTitle: '',
    privacyContents: '',
  },
})

export type DiscussionItemType = {
  id?: string
  lastMessage?: Legacy.LegacyMessage | null
  unreadCount?: number
  occurCard?: Legacy.LegacyCardNotice
  occurMessage?: Message
  chatMessageReply?: Message
  startMessage?: Message | null
  isSkelaton?: boolean
} & ChannelDiscussion

export type DiscussionCreateType = {
  chatMessage: Message
}

export const discussionState = atom<DiscussionItemType | undefined>({
  key: 'discussionState',
  default: undefined,
})

export const replyTypeState = atom<'msg' | 'discussion' | null>({
  key: 'replyTypeState',
  default: null,
})

export type DropdownMenuType = {
  code: string
  pageY: number
  dropdownList: DropdownGroupItem[]
}

export const dropdownMenuState = atom<DropdownMenuType | null>({
  key: 'dropdownMenuState',
  default: null,
})

export const selectedItemState = atom<MemberListItem | undefined>({
  key: 'selectedItemState',
  default: undefined,
})

export const contextMenuState = atom<{
  x: number
  y: number
} | null>({
  key: 'contextMenuState',
  default: null,
})

export type CardReplyMessage = {
  userEmail: string
  userName: string
  message: string
  no: string
  channelNo: string
  roomId: string
}

export const cardReplyMessageState = atom<CardReplyMessage | null>({
  key: 'cardReplyMessageState',
  default: null,
})

export const boardAdminState = atom<ProjectMember[]>({
  key: 'boardAdminState',
  default: [],
})
