export interface Todo {
  no: number
  todo: string
  done: boolean
  isAttach: boolean
  userIds: string
  todoEndDate: string
  missionUser: any
  missionDate: string
  missionStatus: string
  missionTodos: any[]
  files: any[]
}

export enum TodoType {
  PICK = 'P',
  TEAM = 'T',
}

export type MissionType = {
  no?: number
  id: number
  todo: string
  todoType: TodoType
  todoEndDate: null | Date | string
  attach: boolean
  userIds: string
  memberIds?: string
  order: number
  isDone: boolean
  countTodo?: number
  countDone?: number
  donePercent?: number
  missionStatus?: 'X' | 'N' | 'Y'
  missionTodos?: any[]
  missionUser?: any
  isAttach?: boolean
  isFileDownloadAuth?: boolean
}
