import { useCallback, useEffect, useRef } from 'react'

export * from './card'

export function useIsMounted() {
  const isMountedRef = useRef(true)
  const isMounted = useCallback(() => isMountedRef.current, [])

  useEffect(
    () => () => {
      isMountedRef.current = false
    },
    [],
  )

  return isMounted
}
