import { atom, atomFamily, selector } from 'recoil'
import * as api from '../../apis'
import { CardShare, MenuType, UserType } from '../../models'

type SessionType = {
  jwtToken: string
  token: string
  roomId: string
  cardNo: string
  language: string
}

export const sessionInitState = atom({
  key: 'sessionInitState',
  default: true,
})

export const jwtTokenState = atom({
  key: 'jwtTokenState',
  default: '',
})

export const menuState = atomFamily<MenuType | null, string>({
  key: 'menuState',
  default: null,
})

export const sessionState = atom<SessionType>({
  key: 'sessionState',
  default: {
    jwtToken: '',
    token: '',
    roomId: '',
    cardNo: '',
    language: '',
  },
})

export const anonymousUserState = atom<boolean>({
  key: 'anonymousUserState',
  default: false,
})

export const currentUserState = atom<UserType | undefined>({
  key: 'currentUserState',
  default: undefined,
})

export const currentUserSelector = selector({
  key: 'currentUserSelector',
  get: ({ get }) => {
    const currentUser = get(currentUserState)
    return (
      currentUser || {
        no: -1,
        userEmail: '',
        userName: '',
        userGrade: '',
        userState: -1,
        groupChatNo: -1,
        groupFriendNo: -1,
        userGender: '',
        userId: null,
        userIntro: null,
        userPw: null,
      }
    )
  },
})

export const sessionInfoSelector = selector({
  key: 'sessionStateSelector',
  get: ({ get }) => {
    const jwtToken = get(jwtTokenState)
    const session = get(sessionState)
    const currentUser = get(currentUserState)

    return {
      jwtToken,
      token: session.token || sessionStorage.getItem('sessionToken'),
      roomId: session.roomId || sessionStorage.getItem('sessionRoomId'),
      cardNo: session.cardNo || sessionStorage.getItem('sessionCardNo'),
      currentUser: currentUser || api.getCurrentUser(),
      language: session.language || sessionStorage.getItem('sessionLanguage'),
    }
  },
})

export const cardShareState = atom<CardShare | undefined>({
  key: 'cardShareState',
  default: undefined,
})
