import moment from 'moment'
import { Message } from './models'

export const CHANNEL_SIZE = 20

export const MAX_FILE_COUNT = 6

export const removeItemAtIndex = (arr: any[], index: number) => {
  return [...arr.slice(0, index), ...arr.slice(index + 1)]
}

export const replaceItemAtIndex = (
  arr: any[],
  index: number,
  newValue: any
) => {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)]
}

export const messageListSort = (a: Message, b: Message) => {
  const aSort = moment(a.regDate).milliseconds(a.mills || 0)
  const bSort = moment(b.regDate).milliseconds(b.mills || 0)
  if (aSort.isAfter(bSort)) return -1
  else if (aSort.isBefore(bSort)) return 1
  else return 0
}
