import { createContext } from 'react'

export type CardPermission =
  | 'WORK_OWNER'
  | 'WORK_MANAGER'
  | 'BOARD_LEADER'
  | 'CARD_CREATOR'
  | 'BOARD_MEMBER'
  | 'WORK_MEMBER'
  | 'NOT_MEMBER'
  | 'ANONYMOUS'

export type CardTokenType = {
  userNo: number | null
  cardNo: number | null
  permission: CardPermission | null
}
export const AuthContext =
  createContext<AuthProviderContext | undefined>(undefined)

export interface AuthProviderContext {
  cardToken: CardTokenType
}
