import { IonApp } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import React from 'react'
import ReactDOM from 'react-dom'
import { RecoilRoot } from 'recoil'
import App from './App'
import './index.css'
// import * as serviceWorker from './serviceWorker'

const div = document.getElementById('rocket-is-talk-react')

ReactDOM.render(
  <RecoilRoot>
    <IonApp>
      <IonReactRouter>
        <App domElement={div} />
      </IonReactRouter>
    </IonApp>
  </RecoilRoot>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
