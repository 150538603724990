import { Astro } from '@rocket/astronaut'
import { StompSubscription } from '@stomp/stompjs'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import * as api from '../../apis'
import { StompProvider } from '../../contexts/stomp'
import * as socket from '../../data/SocketClient'
import {
  cardDeletedState,
  cardState,
  currentUserState,
  loadingState,
  sessionState,
} from '../../store/recoil'
import AstroProvider from '../astro/AstroProvider'

interface Props {
  astro: Astro
  children: JSX.Element
}

export default function Subscription({ astro, children }: Props) {
  const currentUser = useRecoilValue(currentUserState)
  const { token, roomId, cardNo } = useRecoilValue(sessionState)
  const setLoading = useSetRecoilState(loadingState)
  const [, setCard] = useRecoilState(cardState)
  const [, setCardDeleted] = useRecoilState(cardDeletedState)

  const [subscriptions] = useState<StompSubscription[]>([])

  const { pathname } = useLocation()

  // StompJS card subscription
  const subscribe = useCallback(async () => {
    console.log('subscribe', token, roomId, cardNo, subscriptions)
    if (!token || !roomId || !cardNo) return

    if (cardNo && pathname.indexOf('/card') > -1) {
      console.log('cardNo', cardNo)
      socket
        .subscribeCardTodoUpdate(Number(cardNo), async () => {
          const cardData = await api.getCard()
          console.log('cardData', cardData)
          setCard(() => cardData)
        })
        .then((subscribe) => {
          console.log('subscribeCardTodoUpdate', subscribe)
          subscriptions.push(subscribe)
        })

      socket
        .subscribeCardUpdate(Number(cardNo), async () => {
          setLoading(true)
          astro.readChannel(roomId).then((res) => {
            console.log('readChannel', res)
            setTimeout(async () => {
              const url = `${process.env.REACT_APP_ROCKET_WORK_URL}/card/access/${cardNo}?channelNo=${res.id}&type=CARD`
              window.location.href = url
            }, 1000)
          })
        })
        .then((subscribe) => {
          console.log('subscribeCardUpdate', subscribe)
          subscriptions.push(subscribe)
        })

      socket
        .subscribeCardMove(Number(cardNo), async (res: any) => {
          console.log('subscribeCardMove', res)
          const { body } = res
          const { cardId, channelId } = JSON.parse(body)
          setLoading(true)
          setTimeout(async () => {
            const url = `${process.env.REACT_APP_ROCKET_WORK_URL}/card/access/${cardId}?channelNo=${channelId}&type=CARD`
            window.location.href = url
          }, 1000)
        })
        .then((subscribe) => {
          console.log('subscribeCardMove', subscribe)
          subscriptions.push(subscribe)
        })

      socket
        .subscribeCardDelete(Number(cardNo), async () => {
          setCardDeleted(true)
        })
        .then((subscribe) => {
          console.log('subscribeCardDelete', subscribe)
          subscriptions.push(subscribe)
        })

      socket
        .subscribeProjectUpdate2((message: any) => {
          const { body } = message
          astro.readProject(body).then((res) => {
            console.log('readProject', res, res.isArchive)
            if (res.isArchive) setCardDeleted(true)
          })
        })
        .then((subscribe) => {
          console.log('subscribeProjectUpdate2', subscribe)
          subscriptions.push(subscribe)
        })

      socket
        .subscribeChannelDelete(() => {
          setCardDeleted(true)
        })
        .then((subscribe) => {
          console.log('subscribeChannelDelete', subscribe)
          subscriptions.push(subscribe)
        })

      if (currentUser)
        socket
          .subscribeChannelLeave(currentUser.no, (message: any) => {
            const { body } = message
            console.log('subscribeChannelLeave', message, body, roomId)
            if (body === roomId) {
              setLoading(true)
              astro.readChannel(body).then((res) => {
                console.log('readChannel', res)
                setTimeout(async () => {
                  const url = `${process.env.REACT_APP_ROCKET_WORK_URL}/card/access/${cardNo}?channelNo=${res.id}&type=CARD`
                  window.location.href = url
                }, 1000)
              })
            }
          })
          .then((subscribe) => {
            console.log('subscribeChannelLeave', subscribe)
            subscriptions.push(subscribe)
          })

      api.getCard().then((res) => {
        if (res.projectNo) {
          socket
            .subscribeProjectUpdate(
              String(res.projectNo),
              async (data: any) => {
                console.log('subscribeProjectUpdate', data)
                setLoading(true)
                astro.readChannel(roomId).then((res) => {
                  console.log('readChannel', res)
                  setTimeout(async () => {
                    const url = `${process.env.REACT_APP_ROCKET_WORK_URL}/card/access/${cardNo}?channelNo=${res.id}&type=CARD`
                    window.location.href = url
                  }, 1000)
                })
              }
            )
            .then((subscribe) => {
              console.log('subscribeProjectUpdate', subscribe)
              subscriptions.push(subscribe)
            })
        }
      })
    }
  }, [subscriptions, token, roomId, cardNo, currentUser])

  useEffect(() => {
    subscribe()
    return () => {
      subscriptions.forEach((subscription) => {
        subscription.unsubscribe()
      })
    }
  }, [subscriptions, token, roomId, cardNo, subscribe])

  // console.log(astro.option)

  console.log('currentUser', currentUser, token)
  // astro.readMe().then((res) => console.log('me', res))

  useEffect(() => {}, [])

  return (
    <div>
      <AstroProvider option={astro.option} token={token}>
        <StompProvider
          url={process.env.REACT_APP_URI_WS_TALK + '/websocket'}
          debug={(debug) => console.log('StompProvider - debug', debug)}
          onConnect={(debug) => console.log('StompProvider - onConnect', debug)}
          onWebSocketClose={(debug) => {
            console.log('StompProvider - onWebSocketClose', {
              currentUser,
              debug,
            })
          }}
        >
          {children}
        </StompProvider>
      </AstroProvider>
    </div>
  )
}
