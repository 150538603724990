import { atom } from 'recoil'
import { CardType, ChannelType, DiscussionForm } from '../../models'
import { Discussion } from '@rocket/types'

const initChannelValue = {
  no: -1,
  closed: false,
  roomId: '',
  roomName: '',
  roomDateTime: '',
  members: [],
  unreadCount: 0,
  discussionRoom: false,
}

export const channelState = atom<ChannelType>({
  key: 'channelState',
  default: initChannelValue,
})

export const discussionChannelIdState = atom<string | null>({
  key: 'discussionChannelIdState',
  default: null,
})
export const discussionChannelNoState = atom<string | null>({
  key: 'discussionChannelNoState',
  default: null,
})

const initCardValue = {
  no: '',
  title: '',
  userNo: '',
  content: '',
  roomIds: '',
  noticeDate: null,
  location: '',
  locationEtc: '',
  locationX: -1,
  locationY: -1,
  files: [],
  todos: [],
  regDate: '',
  modDate: '',
  isPublic: false,
}

export const cardState = atom<CardType>({
  key: 'cardState',
  default: initCardValue,
})

export const orgCardState = atom<CardType>({
  key: 'orgCardState',
  default: initCardValue,
})

export const missionNoState = atom<number | null>({
  key: 'missionNoState',
  default: null,
})

export const cardUpdatableState = atom<boolean>({
  key: 'cardUpdatableState',
  default: false,
})

export const cardDiscussionFormState = atom<DiscussionForm>({
  key: 'cardDiscussionFormState',
  default: {
    state: 'normal',
    text: '',
    id: '',
  },
})

export const cardDiscussionChannelState = atom<Discussion>({
  key: 'cardDiscussionChanellState',
  default: {},
})

export const discussionFormTextState = atom<string>({
  key: 'discussionFormTextState',
  default: '',
})
