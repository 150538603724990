import {
  Client,
  messageCallbackType,
  Stomp,
  StompSubscription,
} from '@stomp/stompjs'
import SockJS from 'sockjs-client'

let _authToken: string
let _roomId: string
let client: Client

let _connected: boolean = false
let _connecting: boolean = false
let _onConnect: any = undefined

let _subscribeList: any = undefined

export const setOnConnect = (onConnect: any) => {
  _onConnect = onConnect
}

export const socketConnect = (
  token: string,
  roomId: string,
  onConnect?: any
) => {
  if (_connected || _connecting) return false
  _connecting = true
  _authToken = token
  _roomId = roomId
  _onConnect = onConnect
  client = Stomp.over(
    () => new SockJS(process.env.REACT_APP_URI_WS_TALK + '/websocket')
  )
  client.reconnectDelay = 1000
  client.heartbeatIncoming = 1000
  client.heartbeatOutgoing = 1000
  client.debug = (debug) => {
    console.log('DEBUG - STOMP: ' + debug)
  }
  client.onConnect = (frame) => {
    // console.log('onConnect', frame)
    _connecting = false
    _connected = true
    _subscribeList = new Map()
    if (_onConnect) _onConnect()
  }
  client.activate()

  return true
}

export const sendMessageData = (data: any) => {
  if (!_roomId) return false
  const topic = `/publish/chat/messages/${_roomId}`
  send(topic, data)
}

export const subscribeMessageData = (channelId: string, callback: any) => {
  const topic = `/subscribe/v2/messages/${channelId}`
  return onMessage(topic, callback)
}

export const subscribeMessageDataUpdate = (
  channelId: string,
  callback: any
) => {
  const topic = `/subscribe/v2/messages/${channelId}/update`
  return onMessage(topic, callback)
}

export const subscribeMessageDataDelete = (
  channelId: string,
  callback: any
) => {
  const topic = `/subscribe/v2/messages/${channelId}/delete`
  return onMessage(topic, callback)
}

export const subscribeTypingOn = (channelId: string, callback: any) => {
  const topic = `/subscribe/channel/${channelId}/typing/on`
  return onMessage(topic, callback)
}

export const subscribeTypingOff = (channelId: string, callback: any) => {
  const topic = `/subscribe/channel/${channelId}/typing/off`
  return onMessage(topic, callback)
}

export const subscribeReadMessage = (channelId: string, callback: any) => {
  const topic = `/subscribe/messages/${channelId}/read`
  return onMessage(topic, callback)
}

export const subscribeCardUpdate = (cardNo: number, callback: any) => {
  const topic = `/subscribe/card/${cardNo}/update/`
  return onMessage(topic, callback)
}

export const subscribeCardMove = (cardNo: number, callback: any) => {
  const topic = `/subscribe/card/${cardNo}/move`
  return onMessage(topic, callback)
}

export const subscribeCardDelete = (cardNo: number, callback: any) => {
  const topic = `/subscribe/card/${cardNo}/delete`
  return onMessage(topic, callback)
}

export const subscribeCardTodoUpdate = (cardNo: number, callback: any) => {
  const topic = `/subscribe/card/${cardNo}/todo/update`
  return onMessage(topic, callback)
}

export const typingOn = (
  channelRoomId: string,
  userNo: number,
  recipientNo?: number
) => {
  const topic = `/publish/chat/rooms/${channelRoomId}/typing/on`
  const payload = { userNo, recipientNo }
  send(topic, payload)
}

export const typingOff = (channelRoomId: string, userNo: number) => {
  const topic = `/publish/chat/rooms/${channelRoomId}/typing/off`
  const payload = { userNo }
  send(topic, payload)
}

export const subscribeChannelDelete = (callback: any) => {
  const topic = `/subscribe/v2/delete/${_roomId}`
  return onMessage(topic, callback)
}

export const subscribeProjectUpdate = (projectId: string, callback: any) => {
  const topic = `/subscribe/project/${projectId}/update`
  return onMessage(topic, callback)
}

export const subscribeProjectUpdate2 = (callback: any) => {
  const topic = `/subscribe/project/update`
  return onMessage(topic, callback)
}

export const subscribeChannel = (userNo: number, callback: any) => {
  const topic = `/subscribe/v2/${userNo}/rooms`
  return onMessage(topic, callback)
}

export const subscribeChannelLeave = (userNo: number, callback: any) => {
  const topic = `/subscribe/${userNo}/rooms/leave`
  return onMessage(topic, callback)
}

const onMessage = (
  topic: string,
  callback: messageCallbackType
): Promise<StompSubscription> => {
  return new Promise((resolve, reject) => {
    let isConn = false
    const iv = setInterval(() => {
      if (client && client.connected) isConn = true
      if (isConn) {
        if (_subscribeList.has(topic)) {
          clearInterval(iv)
          resolve(_subscribeList.get(topic))
        } else {
          clearInterval(iv)
          const stompSubscription = client.subscribe(topic, callback)
          _subscribeList.set(topic, stompSubscription)
          resolve(stompSubscription)
        }
      }
    }, 500)
  })
}

const send = (topic: string, payload: any) => {
  let isConn = false
  const iv = setInterval(() => {
    console.log('sending')
    if (client && client.connected) isConn = true
    if (isConn) {
      clearInterval(iv)
      client.publish({
        destination: topic,
        headers: { 'X-AUTH-TOKEN': _authToken },
        body: JSON.stringify(payload),
      })
    }
  }, 500)
}
