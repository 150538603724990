import { i18n } from 'i18next'
import { UserType } from './models'
import { Astro } from '@rocket/astronaut'

import * as api from './apis'

function fallbackCopyTextToClipboard(text: string) {
  var textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    var successful = document.execCommand('copy')
    var msg = successful ? 'successful' : 'unsuccessful'
    console.log('Fallback: Copying text command was ' + msg)
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err)
  }

  document.body.removeChild(textArea)
}

export function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log('Async: Copying to clipboard was successful!')
    },
    function (err) {
      console.error('Async: Could not copy text: ', err)
    }
  )
}

interface LegacyMessage {
  chatMessageReply?: any
  writer: any
  message: any
  messageSymbol?: any
  regDate: any
  type?: any
  attachments?: any
  deleted: any
  no: any
  name: any
  receivers?: any
  moreList: any
}

export function legacyMessageToDiscussion(d: LegacyMessage) {
  let chatMessageReply: any = []
  if (d.chatMessageReply) {
    chatMessageReply = replyToChatLegacyMessage(d.chatMessageReply)
  }

  let { file, recipient } = getFileAndRecipientFromLegacyMessage(d)

  return {
    imageUrl: `${process.env.REACT_APP_URI_CDN_SECURE}/profile/${d.writer.userEmail}`,
    message: d.message,
    messageSymbol: d.messageSymbol,
    regDate: d.regDate,
    type: d.type,
    origin: d.type,
    file,
    recipient,
    writer: d.writer,
    deleted: d.deleted,
    // 특징적인 부분
    no: d.no,
    username: d.name ? d.name : d.writer.nickName,
    chatMessageReply,
    moreList: d.moreList,
    name: d.name ? d.name : d.writer.nickName,
  }
}

function replyToChatLegacyMessage(d: LegacyMessage): any {
  let { file, recipient } = getFileAndRecipientFromLegacyMessage(d)

  return {
    imageUrl: `${process.env.REACT_APP_URI_CDN_SECURE}/profile/${d.writer.userEmail}`,
    message: d.message,
    messageSymbol: d.messageSymbol,
    regDate: d.regDate,
    type: d.type,
    file,
    recipient,
    writer: d.writer,
    deleted: d.deleted,
    no: d.no,
    name: d.name ? d.name : d.writer.nickName,
  }
}

function getFileAndRecipientFromLegacyMessage(d: any): {
  file: any
  recipient: any
} {
  let file, recipient
  if (d.file) {
    file = {
      ...d.file,
      url: `${process.env.REACT_APP_URI_CDN_SECURE}/images/${d.file?.fileKey}/${d.file?.filename}`,
    }
  }

  if (d.recipient) {
    recipient = d.recipient
  }

  return {
    file,
    recipient,
  }
}

interface ChatReply {
  id: any
  name: any
  writer: any
  content: any
  symbol: any
  created: any
  origin: any
  attachments: any
  receivers: any
  deleted: any
}

function replyToChatMessage(d: ChatReply): any {
  let { file, recipient } = getFileAndRecipient(d)

  return {
    imageUrl: `${process.env.REACT_APP_URI_CDN_SECURE}/profile/${d.writer.userEmail}`,
    message: d.content,
    messageSymbol: d.symbol,
    regDate: d.created,
    type: d.origin,
    file,
    recipient,
    writer: d.writer,
    deleted: d.deleted,
    no: d.id,
    name: d.name ? d.name : d.writer.nickName,
  }
}

export function messageToDiscussion(
  d: any,
  chatReply: any[],
  currentUser: UserType,
  i18n: i18n,
  boardAdminIndex: number
) {
  let chatMessageReply: any[] = []

  for (const item of chatReply) {
    if (item.reply.id === d.id) {
      chatMessageReply.push({
        ...replyToChatMessage(item),
        moreList: discussionReplyAction(
          currentUser,
          item,
          i18n,
          boardAdminIndex
        ),
      })
    }
  }

  let { file, recipient } = getFileAndRecipient(d)

  // if (d.reply) {
  //   chatMessageReply = replyToChatMessage(d.reply)
  // }
  return {
    imageUrl: `${process.env.REACT_APP_URI_CDN_SECURE}/profile/${d.writer.userEmail}`,
    message: d.content,
    messageSymbol: d.symbol,
    regDate: d.created,
    type: d.origin,
    file,
    recipient,
    writer: d.writer,
    deleted: d.deleted,
    no: d.id,
    username: d.name ? d.name : d.writer.nickName,
    chatMessageReply,
  }
}
function getFileAndRecipient(d: any): { file: any; recipient: any } {
  let file, recipient
  if (d.attachments?.length > 0) {
    file = {
      ...d.attachments[0],
      url: `${process.env.REACT_APP_URI_CDN_SECURE}/images/${d.attachments[0]?.fileKey}/${d.attachments[0]?.filename}`,
    }
  }

  if (d.receivers?.length > 0) {
    recipient = d.receivers[0]
  }

  return {
    file,
    recipient,
  }
}

export function downloadUrlAs(
  downloadUrl: string,
  filename: string,
  headers: { [key: string]: any }
) {
  fetch(downloadUrl, { method: 'GET', headers })
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = filename
      document.body.appendChild(a)
      a.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(url)
      }, 60000)
      a.remove()
    })
    .catch((e) => {
      console.error('downloadError', e)
    })
}

export function discussionAction(
  currentUser: UserType,
  message: any,
  i18n: i18n,
  boardAdminIndex: number
) {
  let actionList: {
    title: string
    state: string
  }[] = []

  if (message.origin !== 'file') {
    actionList.push({
      title: i18n.t('morelistaction.copy'),
      state: 'copy',
    })
  }

  if (message.writer.no === currentUser.no) {
    if (message.origin !== 'file') {
      actionList.push({
        title: i18n.t('morelistaction.modify'),
        state: 'modify',
      })
    }
    actionList.push({
      title: i18n.t('morelistaction.delete'),
      state: 'delete',
    })
  } else {
    if (boardAdminIndex > -1) {
      actionList.push({
        title: i18n.t('morelistaction.delete'),
        state: 'delete',
      })
    }
  }

  actionList.push({
    title: i18n.t('morelistaction.reply'),
    state: 'reply',
  })

  return actionList
}

export function discussionReplyAction(
  currentUser: UserType,
  message: any,
  i18n: i18n,
  boardAdminIndex: number
) {
  let actionList: {
    title: string
    state: string
  }[] = []

  if (message.origin !== 'file') {
    actionList.push({
      title: i18n.t('morelistaction.copy'),
      state: 'copy',
    })
  }

  if (message.writer.no === currentUser.no) {
    if (message.origin !== 'file') {
      actionList.push({
        title: i18n.t('morelistaction.modify'),
        state: 'modify',
      })
    }
    actionList.push({
      title: i18n.t('morelistaction.delete'),
      state: 'delete',
    })
  } else {
    if (boardAdminIndex > -1) {
      actionList.push({
        title: i18n.t('morelistaction.delete'),
        state: 'delete',
      })
    }
  }

  return actionList
}

export function fetchDiscussion(astro: Astro, discussionRoomId: string) {
  return astro
    .readChannelDiscussionOccurred(discussionRoomId)
    .then((res: any) => {
      return api.getUnreadCount(discussionRoomId).then((unreadCount) => ({
        ...res,
        unreadCount,
      }))
    })
    .then((res) => {
      return api.getLastMessage(discussionRoomId).then((lastMessage) => ({
        ...res,
        lastMessage,
      }))
    })
    .then((res) => {
      if (res.occurCardNo) {
        return astro
          .readCard(String(res.occurCardNo), res.discussion.no)
          .then((occurCard) => ({ ...res, occurCard }))
      } else if (res.occurMessageNo) {
        return api.getMessage(res.occurMessageNo).then((occurMessage) => ({
          ...res,
          occurMessage,
        }))
      } else return res
    })
}
