import React, { FC, useEffect, useRef, useState } from 'react'
import {
  Client,
  messageCallbackType,
  Stomp,
  StompConfig,
  StompHeaders,
  StompSubscription,
} from '@stomp/stompjs'
import SockJS from 'sockjs-client'
import { StompContext } from './StompContext'

export const stompHeaders = {
  'content-type': 'application/json',
}

export type StompUserType = {
  no: number
  userState: number
  platform: 'mobile' | 'web'
}

interface Props extends StompConfig {
  url: string
  // stompUser: StompUserType
  children: JSX.Element
}

interface StompSessionSubscription {
  destination: string
  callback: messageCallbackType
  headers: StompHeaders
  subscription?: StompSubscription
}

export const StompProvider: FC<Props> = ({
  url,
  // stompUser,
  children,
  ...stompOptions
}) => {
  const [client, setClient] = useState<Client>()
  const subscriptionRequests = useRef(new Map())

  useEffect(() => {
    const _client = Stomp.over(() => new SockJS(url))
    _client.reconnectDelay = 1000
    _client.heartbeatIncoming = 1000
    _client.heartbeatOutgoing = 1000

    _client.onConnect = (frame) => {
      if (stompOptions.onConnect) stompOptions.onConnect(frame)
      subscriptionRequests.current.forEach((value) => {
        value.subscription = _client.subscribe(
          value.destination,
          value.callback,
          value.headers
        )
      })

      // _client.publish({
      //   destination: '/publish/info',
      //   body: JSON.stringify({
      //     userNo: stompUser.no,
      //   }),
      //   headers: stompHeaders,
      // })

      setClient(_client)
    }

    _client.onWebSocketClose = function (event) {
      if (stompOptions.onWebSocketClose) stompOptions.onWebSocketClose(event)

      setClient(undefined)
    }

    if (!stompOptions.onStompError) {
      _client.onStompError = function (frame) {
        throw frame
      }
    }
    _client.activate()

    return () => {
      _client.deactivate()
    }
  }, [url])

  const subscribe = (
    destination: string,
    callback: messageCallbackType,
    headers: StompHeaders = {}
  ) => {
    const subscriptionId = Math.random().toString(36).substr(2, 9)
    const subscriptionRequest: StompSessionSubscription = {
      destination,
      callback,
      headers,
    }

    subscriptionRequests.current.set(subscriptionId, subscriptionRequest)

    if (client && client.connected) {
      subscriptionRequest.subscription = client.subscribe(
        destination,
        callback,
        headers
      )
    }

    return () => {
      const subscriptionData = subscriptionRequests.current.get(subscriptionId)

      if (subscriptionData.subscription) {
        subscriptionData.subscription.unsubscribe()
      }

      subscriptionRequests.current.delete(subscriptionId)
    }
  }

  return (
    <StompContext.Provider value={{ client, subscribe }}>
      {children}
    </StompContext.Provider>
  )
}
