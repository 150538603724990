import { Astro } from '@rocket/astronaut'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import * as api from '../../apis'
import i18nInstance from '../../i18n/config'
import {
  CardClassification,
  CardType,
  ChannelType,
  MemberType,
  ProjectMemberType,
  RoomType,
  UserType,
} from '../../models'
import { getCardDataStates, useIsMounted } from '../../modules/hooks'
import {
  cardDiscussionFormState,
  cardDiscussionPageState,
  cardDiscussionListState,
  cardShareState,
  cardState,
  cardUpdatableState,
  channelMemberState,
  channelState,
  currentUserState,
  loadingState,
  missionNoState,
  orgCardState,
  projectMemberState,
  sessionState,
  cardDiscussionState,
  headerTypeState,
  discussionChannelIdState,
  discussionSelectedIndexState,
  projectNameState,
  headerSettingState,
  currentProjectState,
  termsState,
  discussionState,
  discussionChannelNoState,
  discussionSelectedReplyIndexState,
  boardAdminState,
} from '../../store/recoil'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Discussion } from '@rocket/types'
import { discussionAction, messageToDiscussion } from '../../utils'
import { AuthProvider } from '../../contexts/auth'

interface Props {
  children: JSX.Element
  pathname: string
  astro: Astro
  push: (url: string) => void
}

export default function DataLoad({ children, pathname, astro, push }: Props) {
  const setLoading = useSetRecoilState(loadingState)
  const [session] = useRecoilState(sessionState)
  const currentUser = useRecoilValue(currentUserState)
  const [channel, setChannel] = useRecoilState(channelState)
  const [discussChannelId, setDiscussionChannelId] = useRecoilState(
    discussionChannelIdState
  )
  const setDiscussionChannelNo = useSetRecoilState(discussionChannelNoState)
  const setDiscussion = useSetRecoilState(discussionState)
  const setCardDiscussion = useSetRecoilState(cardDiscussionState)
  const setHeaderType = useSetRecoilState(headerTypeState)
  const setChannelMember = useSetRecoilState(channelMemberState)

  const setCurrentProject = useSetRecoilState(currentProjectState)
  const [projectMember, setProjectMember] = useRecoilState(projectMemberState)
  const [card, setCard] = useRecoilState(cardState)
  const setOrgCard = useSetRecoilState(orgCardState)
  const setMissionNo = useSetRecoilState(missionNoState)
  const setCardUpdatable = useSetRecoilState(cardUpdatableState)
  const [discussions, setDiscussions] = useRecoilState<Discussion[]>(
    cardDiscussionListState
  )
  const [discussionPage, setDiscussionPage] = useRecoilState(
    cardDiscussionPageState
  )
  const [discussionForm, setDiscussionForm] = useRecoilState(
    cardDiscussionFormState
  )

  const setCardShare = useSetRecoilState(cardShareState)

  const isMounted = useIsMounted()

  const mySwal = withReactContent(Swal)

  const setProjectName = useSetRecoilState(projectNameState)

  const setSelectedIndex = useSetRecoilState(discussionSelectedIndexState)
  const setReplySelectedIndex = useSetRecoilState(
    discussionSelectedReplyIndexState
  )

  const setHeaderSetting = useSetRecoilState(headerSettingState)

  const setTerms = useSetRecoilState(termsState)

  const [boardAdmins, setBoardAdmins] = useRecoilState(boardAdminState)

  const errorModal = (msg: string) => {
    mySwal.fire({
      title: <small style={{ color: '#bd0000' }}>{msg}</small>,
      showConfirmButton: false,
      timer: 1000,
      backdrop: false,
    })

    setTimeout(() => window.close(), 1000)
  }

  const loadTranslationByUserLocale = useCallback(() => {
    function changeHtmlLangAttribute(lang: string) {
      document.documentElement.lang = lang
    }
    astro
      .readMe()
      .then((user) => {
        const lang = user.lang ?? session.language ?? 'en'
        i18n.changeLanguage(lang)
        changeHtmlLangAttribute(lang)
        // return loadLanguageAsync(lang)
      })
      .catch(console.error)
  }, [session])

  // 데이터 세팅
  async function setDataStates() {
    setLoading(true)
    let channelData: ChannelType | null = null
    let memberListData: MemberType[] | null = null

    const values = await Promise.all([api.getChannel(), api.getMemberList()])
    channelData = values[0]
    memberListData = values[1]

    if (!channelData || !memberListData) {
      push('/maintenance')
      return
    }

    if (channelData.discussionRoom) {
      const occurred = await astro.readChannelDiscussionOccurred(
        channelData.roomId
      )
      console.log('channelData', channelData, occurred)

      let channelRoomId
      if (occurred.chatRoomList && occurred.chatRoomList.length > 0) {
        channelRoomId = occurred.chatRoomList[0].roomId
      } else {
        channelRoomId = occurred.chatRoom?.roomId
      }

      const discussionRoomId = occurred.discussion.roomId

      channelData = await api.getChannel(channelRoomId)
      memberListData = await api.getMemberList(channelRoomId)
      const discussion = await astro
        .readChannelDiscussionOccurred(discussionRoomId)
        .then((res: any) => {
          return api.getUnreadCount(discussionRoomId).then((unreadCount) => ({
            ...res,
            unreadCount,
          }))
        })
        .then((res) => {
          return api.getLastMessage(discussionRoomId).then((lastMessage) => ({
            ...res,
            lastMessage,
          }))
        })
        .then((res) => {
          if (res.occurCardNo) {
            return astro
              .readCard(String(res.occurCardNo), res.discussion.no)
              .then((occurCard) => ({ ...res, occurCard }))
          } else if (res.occurMessageNo) {
            return api.getMessage(res.occurMessageNo).then((occurMessage) => ({
              ...res,
              occurMessage,
            }))
          } else return res
        })
      setDiscussion(discussion)
    }

    if (channelData.projectNo && channelData.projectElementNo) {
      const projectElementNo = channelData.projectElementNo
      const project = await astro.readProject(String(channelData.projectNo))
      setCurrentProject(project)
      setProjectName(project.title)
      const currentElements = project.elements.find(
        (o) => o.id === String(projectElementNo)
      )

      let color = 'mono.gray'

      if (currentElements) {
        const isFirst = currentElements.order === 0
        const isLast = currentElements.order === currentElements.maxOrder
        color =
          project.type.code === 'WFP' && isFirst
            ? 'main.yellow'
            : project.type.code === 'WFP' && isLast
            ? 'main.blue'
            : project.type.code === 'WFP'
            ? 'main.turquoise'
            : currentElements.labelObject
            ? currentElements.labelObject
            : 'mono.paleWhite'
      }

      setHeaderSetting({ type: project.type.code, color })
      setBoardAdmins(
        project.members.filter(
          (o) => o.auth === 'OWNER' || o.auth === 'MANAGER'
        )
      )
    }

    setChannel(channelData)
    setChannelMember(memberListData)

    if (channelData.projectNo) {
      const project = await astro.readProject(String(channelData.projectNo))
      const projectOwner = project.members.find((o) => o.auth === 'OWNER')
      if (projectOwner) {
        setChannelMember((prev) => [
          ...prev,
          {
            no: Number(projectOwner.userId),
            member: {
              no: Number(projectOwner.userId),
              userEmail: projectOwner.email,
              userName: projectOwner.name,
            },
          },
        ])
      }
    }

    if (channelData.projectNo && currentUser) {
      const projectMemberData: ProjectMemberType | null | undefined =
        await api.getProjectMember(channelData.projectNo, currentUser.no)
      if (!projectMemberData) {
        // TODO: 해당 프로젝트에 참여되지 않은 사용자 시나리오 필요
        // push('/maintenance')
        // return
      } else {
        setProjectMember(projectMemberData)
      }
    }

    if (pathname.indexOf('/card') > -1) {
      await setCardDataStates(channelData, memberListData)
    } else if (pathname.indexOf('/chat') > -1) {
      await setCardDataBriefStates(channelData)
    }

    if (window.opener) {
      // identify opener
      const action = 'identifyOpener'
      const unique = `${Math.random() * Number.MAX_VALUE}`

      sessionStorage.setItem('identifySession', unique)
      const payload = {
        unique,
      }
      window.opener.postMessage(
        {
          action,
          payload,
        },
        '*'
      )
    }

    if (channelData.discussionRoom) {
      const discussionNo = `${channelData.no ?? ''}`
      if (pathname.startsWith('/card')) {
      } else {
        setHeaderType('discussion')
      }

      const discussionOccurred = await astro.readChannelDiscussionOccurred(
        channelData.roomId
      )

      if (discussionOccurred.occurMessageNo) {
        const message = await api.getMessage(
          String(discussionOccurred.occurMessageNo)
        )
        setCardDiscussion({
          no: 0,
          ...discussionOccurred.discussion,
          profileUrl: message.writer
            ? `${process.env.REACT_APP_URI_CDN_SECURE}/profile/${message.writer.userEmail}`
            : undefined,
        })
      }

      const openerChannelId = sessionStorage.getItem('openerChannelId') ?? ''
      if (openerChannelId) {
        console.debug('opener is available: ', openerChannelId)
        api
          .getChannelDiscussion(openerChannelId, discussionNo)
          .then(({ discussion }) => {
            setCardDiscussion(discussion)
          })
      } else {
        window.addEventListener(
          'message',
          (message) => {
            const { action, payload } = message.data
            if (action === 'presentOwner') {
              api
                .getChannelDiscussion(payload.channelId, discussionNo)
                .then(({ discussion }) => {
                  setCardDiscussion(discussion)
                })
            }
          },
          {
            once: true,
          }
        )
        console.debug('opener is not available, set listener')
      }
    }

    setLoading(false)
  }

  // 카드 데이터 세팅
  async function setCardDataStates(
    channelData: ChannelType,
    channelMembers: MemberType[]
  ) {
    const cardData = await getCardDataStates(
      currentUser as UserType,
      channelData
    )
    if (!cardData) {
      // push('/maintenance')
      setTimeout(() => {
        errorModal('삭제된 카드입니다')
      }, 500)
      return
    }

    setCard(() => cardData)
    setOrgCard(() => cardData)

    checkCardUpdatable(channelData, channelMembers, cardData)

    // 토론 채널이 존재할경우 discussionChannelId 설정
    astro
      .readCardDiscussionList(channelData.roomId, cardData.no)
      .then((res) => (res.length > 0 ? res[0] : null))
      .then((res) => {
        if (res) {
          return { roomId: res.discussion.roomId, no: res.discussion.no }
        } else {
          return null
        }
      })
      .then((res) => {
        if (res) {
          setDiscussionChannelId(res.roomId)
          setDiscussionChannelNo(res.no)
        }
      })

    const mission: any | null = await api.getCardMission(channelData.roomId)
    console.log('mission', mission)

    setMissionNo(() => (mission ? mission.no : null))

    if (pathname.indexOf('/undefined') > -1) {
      setLoading(false)
      push(`/card/${card.type?.toLowerCase()}`)
    }

    setCardShare(cardData.cardShare)
  }

  // 이슈(카드) 수정 가능 여부 체크
  function checkCardUpdatable(
    channelData: ChannelType,
    channelMembers: MemberType[],
    cardData: CardType
  ) {
    // 채널 종료 시 수정불가
    if (channelData.closed) {
      setCardUpdatable(false)
      return
    }

    // 현재 사용자가 프로젝트 관리자일경우 수정가능
    if (projectMember && projectMember.level >= 90) {
      setCardUpdatable(true)
      return
    }

    // 공개수정가능이 아니고
    // 현재 사용자가 카드의 작성자가 아니고
    // 현재 사용자가 채널 관리자가 아닐 시 수정불가
    if (
      !cardData.isPublic &&
      cardData.user?.no !== (currentUser as UserType).no &&
      !channelMembers.some(
        (channelMember) =>
          channelMember.member?.no === (currentUser as UserType).no &&
          !!channelMember.owner
      )
    ) {
      setCardUpdatable(false)
      return
    }

    // 타입이 일정일 시, 일정이 설정되어있으며 일정 시간이 지났을 경우 수정 불가
    if (
      cardData.type === CardClassification.ATTEND &&
      !!cardData.noticeDate &&
      cardData.noticeDate < new Date()
    ) {
      setCardUpdatable(false)
      return
    }

    setCardUpdatable(true)
  }

  async function getCardData() {
    setLoading(true)
    const terms = await api.getTerms('pro_terms')
    const privacy = await api.getTerms('pro_privacy')
    setTerms({
      termsTitle: terms.title,
      termsContents: terms.contents,
      privacyTitle: privacy.title,
      privacyContents: privacy.contents,
    })
    const cardData = await getCardDataStates(currentUser as UserType, channel)
    if (cardData) {
      const channel = await astro.readChannel(cardData.roomIds)
      const project = await astro.readProjectBrief(channel.projectId)
      setProjectName(project.title)
      const element = await astro
        .readProjectElement(
          channel.projectId,
          (channel as any).projectElementNo
        )
        .catch((err) => {
          console.log('err', err)
        })
      if (element) {
        const isFirst = element.order === 0
        const isLast = element.order === element.maxOrder
        setHeaderSetting({
          type: project.typeCode,
          color:
            project.typeCode === 'WFP' && isFirst
              ? 'main.yellow'
              : project.typeCode === 'WFP' && isLast
              ? 'main.blue'
              : project.typeCode === 'WFP'
              ? 'main.turquoise'
              : element.labelObject
              ? element.labelObject
              : 'mono.paleWhite',
        })
      } else {
        setHeaderSetting({
          type: project.typeCode,
          color: 'mono.paleWhite',
        })
      }
      setChannel((prev) => {
        return {
          ...prev,
          roomName: channel.roomName,
          roomType: channel.roomType as RoomType,
        }
      })
      setCard(() => cardData)
      setOrgCard(() => cardData)
    } else {
      // push('/maintenance')
      setTimeout(() => {
        errorModal('삭제된 카드입니다')
      }, 500)
    }
    setLoading(false)
  }

  // 카드 데이터 세팅 (채팅)
  async function setCardDataBriefStates(channelData: ChannelType) {
    const terms = await api.getTerms('pro_terms')
    const privacy = await api.getTerms('pro_privacy')
    setTerms({
      termsTitle: terms.title,
      termsContents: terms.contents,
      privacyTitle: privacy.title,
      privacyContents: privacy.contents,
    })
    const cardData = await api.getCard()
    if (cardData) {
      if (cardData.projectNo) {
        astro.readProjectBrief(cardData.projectNo).then((project) => {
          setProjectName(project.title)
        })
      }
      setCardDiscussion((prev) => {
        return {
          ...prev,
          no: cardData.no,
          roomId: cardData.roomIds,
          roomName: cardData.title,
          cardType: cardData.type,
        }
      })
    }
  }

  // 세션 세팅 후 필요 데이터 로딩
  useEffect(() => {
    if (!isMounted()) return
    const { token, roomId } = session

    if (!token || !roomId || !currentUser) return
    astro.token(token)
    loadTranslationByUserLocale()
    if (pathname.indexOf('Brief') === -1) {
      void setDataStates()
    } else {
      getCardData()
    }
  }, [isMounted, session, currentUser, astro])

  useEffect(() => {
    if (!isMounted()) return
    const { token, roomId, cardNo } = session

    if (!token || !roomId || !cardNo || !currentUser) return

    astro.token(token)

    if (discussChannelId) {
      astro
        .readChannelMessageList(
          discussChannelId,
          discussionPage.page,
          discussionPage.size
        )
        .then((data: any[]) => {
          console.debug({ data })
          const boardAdminIndex = boardAdmins.findIndex(
            (o) => o.userId === String(currentUser.no)
          )
          const chatReply = data.filter((d) => d.reply)
          const withoutChatReply = data.filter((d) => !d.reply)
          const _aligned = withoutChatReply.map((d) => ({
            ...messageToDiscussion(
              d,
              chatReply,
              currentUser,
              i18n,
              boardAdminIndex
            ),
            moreList: discussionAction(currentUser, d, i18n, boardAdminIndex),
          }))
          setDiscussions(_aligned)
        })
    } else {
    }
  }, [isMounted, discussionPage, session, astro, discussChannelId])

  useEffect(() => {
    if (!isMounted()) return
    const { token, cardNo, roomId } = session
    if (!token) return

    astro.token(token)

    // Remote API Call 이 들어가는 부분

    console.log('discussion-Form-', discussionForm)
    if (discussionForm.state !== 'modify') {
      setSelectedIndex(-1)
      setReplySelectedIndex(undefined)
    }
    switch (discussionForm.state) {
      case 'error':
        break
      case 'normal':
        setSelectedIndex(-1)
        setReplySelectedIndex(undefined)
        break
      case 'modify':
        break
      case 'delete':
        if (discussChannelId)
          astro.deleteCardDiscussionMessage(
            discussChannelId,
            discussionForm.id ?? ''
          )
        setDiscussionForm({
          text: '',
          state: 'normal',
        })
        break
      case 'submit':
        if (discussChannelId && discussionForm.id) {
          astro
            .updateCardDiscussionMessage(discussChannelId, discussionForm.id, {
              message: discussionForm.text,
            })
            .then((res) => {
              setDiscussionForm({
                ...discussionForm,
                state: 'sent',
              })
            })
        } else {
          // 현재 토론이 생성돼있는지에 따라, 토론방 생성 후 메시지 전송
          if (discussChannelId) {
            astro
              .createCardDiscussionMessage(discussChannelId, cardNo, {
                message: discussionForm.text,
              })
              .then((res) => {
                setDiscussionForm({
                  ...discussionForm,
                  state: 'sent',
                })
              })
          } else {
            astro
              .startCardDiscussion(
                roomId,
                cardNo,
                discussionForm.text,
                card.title
              )
              .then((discussion) => {
                setDiscussionChannelId(discussion.discussion.roomId)
                setCardDiscussion({
                  ...discussion.discussion,
                  no: discussion.discussion.id,
                  roomType: 'G',
                })
                setDiscussionForm({
                  ...discussionForm,
                  state: 'sent',
                })
              })
          }
        }
        setDiscussionForm({
          ...discussionForm,
          state: 'sending',
        })
        break
      case 'sending':
        break
      case 'sent':
        setDiscussionPage({ ...discussionPage })
        setDiscussionForm({
          text: '',
          state: 'normal',
        })
        break
    }
  }, [isMounted, discussions, discussionForm.state])

  // 라우트 변경 이밴트
  useEffect(() => {
    if (!isMounted()) return
    const cardUrl = '/card'
    const missionUrl = '/card/mission'
    const attendUrl = '/card/attend'

    if (channel.closed) {
      if (
        (pathname !== missionUrl || pathname !== missionUrl + '/') &&
        pathname.indexOf(missionUrl) > -1
      ) {
        push('/card/mission')
      } else if (
        (pathname !== attendUrl || pathname !== attendUrl + '/') &&
        pathname.indexOf(attendUrl) > -1
      ) {
        push('/card/attend')
      }
    } else if (pathname.indexOf(cardUrl) > -1) {
      if (pathname.indexOf('undefined') > -1) {
        push(`${cardUrl}/${card.type?.toLowerCase()}/${session.token}`)
      }
    }
  }, [isMounted, pathname])

  const { i18n } = useTranslation()

  useEffect(() => {
    console.log('i18next', i18n, i18nInstance, currentUser?.lang ?? 'en')
    i18n.changeLanguage(currentUser?.lang ?? 'en')
    i18nInstance.changeLanguage(currentUser?.lang ?? 'en')
  }, [currentUser])

  return (
    <AuthProvider token={card.permissionToken}>
      <>{children}</>
    </AuthProvider>
  )
}
