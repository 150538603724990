import { createContext } from 'react'
import type { Client, messageCallbackType, StompHeaders } from '@stomp/stompjs'

export const StompContext = createContext<
  StompSessionProviderContext | undefined
>(undefined)

export interface StompSessionProviderContext {
  client?: Client
  subscribe: (
    destination: string,
    callback: messageCallbackType,
    headers?: StompHeaders,
  ) => () => void
}
