import { getFontStyle, getRealColor } from '@rocket/components'
import React from 'react'

interface Props {
  visible: boolean
}

export default function CardDeleted({ visible }: Props) {
  return (
    <div
      style={{
        ...getFontStyle('titleRegular'),
        lineHeight: undefined,
        display: visible ? 'flex' : 'none',
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: getRealColor('mono.white'),
        justifyContent: 'center',
        paddingTop: 50,
        zIndex: 10,
      }}
    >
      <span>
        해당{' '}
        <span style={{ color: getRealColor('main.blue') }}>페이지는 삭제</span>
        되어
        <br />더 이상 존재하지 않습니다
      </span>
    </div>
  )
}
