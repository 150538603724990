import { UserType } from './user'

export interface Page<T> {
  content: T[]
  first: boolean
  last: boolean
  number: number
  numberOfElements: number
  size: number
  totalElements: number
  totalPages: number
}

export interface Message {
  no: string
  noticeNo: number
  refNo: number
  messageKey: string
  messageSymbol: string | null
  type: string
  message: string
  name: string
  userProfile: string
  regDate: string
  mills: number
  unreadIds: number[]
  file: any
  member: any
  writer: any
  recipient: any
  order: number
  deleted: boolean
  isDel: 'Y' | 'N'
  chatMessageReply: null | Message
  lang?: string
  remoteFiles?: any[]
}

export enum MessageState {
  NONE,
  WAIT,
  LOADING,
  SUCCESS,
}

export type FileType = {
  no: number
  fileKey: string
  filename: string
  ext: string
  originalFilename: string
  fileSize: number
  mimeType: string
  user: UserType
  regDate: string
}

export type ValidationFilterField = 'todo'

export enum ValidationMsg {
  todo = 'board.card.requiretodo',
}

export enum ButtonType {
  UPDATE = 'U',
  DELETE = 'D',
}

export enum ButtonLabel {
  U = 'board.card.edit',
  D = 'board.card.delete',
}

export enum ButtonIcon {
  U = 'xi-pen',
  D = 'xi-trash-o',
}

export enum UpdateButtonType {
  DETAIL = 'DETAIL',
  DEADLINE = 'DEADLINE',
  TIME = 'TIME',
  FILE_ATTACH = 'FILE_ATTACH',
  MAP = 'MAP',
  USERS = 'USERS',
  EMOJI = 'EMOJI',
}

export enum UpdateButtonLabel {
  DETAIL = 'board.card.adddesc',
  DEADLINE = 'board.card.setdue',
  TIME = 'board.card.time',
  FILE_ATTACH = 'board.card.addfile',
  MAP = 'board.card.setlocation',
  USERS = 'board.card.paticipants',
  EMOJI = 'board.card.attendees',
}

export enum UpdateButtonIcon {
  DETAIL = 'xi-paper',
  DEADLINE = 'xi-clock-o',
  TIME = 'xi-clock-o',
  FILE_ATTACH = 'xi-attachment',
  MAP = 'xi-map-o',
  USERS = 'xi-users',
  EMOJI = 'xi-emoticon',
}

export type AddressReqType = {
  query: string
  analyze_type?: // 기본값 similar
  | 'similar' // 입력한 건물명과 일부만 매칭될 경우에도 확장된 검색 결과 제공
    | 'exact' // 주소의 정확한 건물명이 입력된 주소패턴일 경우에 한해, 입력한 건물명과 정확히 일치하는 검색 결과 제공
  page?: number // 1~45 사이의 결과 페이지 번호 (기본값: 1)
  size?: number // 한 페이지에 보여질 문서의 개수. 1~30 사이의 값(기본값: 10)
}

export enum AddressClassification {
  REGION = 'REGION', // 지명
  ROAD = 'ROAD', // 도로명
  REGION_ADDR = 'REGION_ADDR', // 지번 주소
  ROAD_ADDR = 'ROAD_ADDR', // 도로명 주소
}

export type AddressType = {
  x: number
  y: number
  address_name: string // 지번 또는 도로명 주소
  road_address_name?: string // 도로명 주소
  address_type?: AddressClassification
  phone?: string
  place_name?: string // 장소명, 업체명
  category_group_name?: string // 장소 분류명
  address?: {
    // 지번주소
    address_name: string
    zip_code: string // 우편번호
  }
  road_address?: {
    // 도로명주소
    address_name: string
    zone_no: string // 우편번호
  }
}

export type AddressResType = {
  documents: AddressType[]
  meta: {
    is_end: boolean // 검색된 문서 총개수
    pageable_count: number // 노출가능 문서수
    total_count: number // 마지막 페이지 여부
  }
}

export type MenuType = {
  isShow: boolean
  xPos: number
  yPos: number
  auth: string[]
  item: Message
}
