import { IonLoading, IonRouterOutlet } from '@ionic/react'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'
import '@ionic/react/css/display.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/float-elements.css'
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/typography.css'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { Astro } from '@rocket/astronaut'
import AstroNav from '@rocket/astronaut-nav'
import React, { lazy, Suspense } from 'react'
import { Route, useHistory, useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import './App.css'
import CardDeleted from './jsx/foundations/CardDeleted'
import DataLoad from './jsx/pages/DataLoad'
import Subscription from './jsx/pages/Subscription'
import { useIsMounted } from './modules/hooks'
import {
  cardDeletedState,
  isTalkInputFocusState,
  loadingState,
} from './store/recoil/globalStates'
/* Theme variables */
import { BackdropProvider, ModalDialog } from '@rocket/components'
import { I18nextProvider } from 'react-i18next'
import i18nInstance from './i18n/config'
import './theme/variables.css'

const AuthContainer = lazy(() => import('./jsx/pages/AuthContainer'))
const CardContainer = lazy(() => import('./jsx/pages/CardContainer'))
const CardAttendContainer = lazy(
  () => import('./jsx/pages/CardAttendContainer')
)
const CardMissionContainer = lazy(
  () => import('./jsx/pages/CardMissionContainer')
)
const CardGatheringContainer = lazy(
  () => import('./jsx/pages/CardGatheringContainer')
)
const MainContainer = lazy(() => import('./jsx/pages/MainContainer'))
const TalkContainer = lazy(() => import('./jsx/pages/TalkContainer'))
const NoPage = lazy(() => import('./jsx/pages/NoPage'))
const Maintenance = lazy(() => import('./jsx/pages/Maintenance'))

declare global {
  interface Window {
    kakao: any
    daum: any
  }
}

interface Props {
  domElement: Element | null
}

const App: React.FC<Props> = ({ domElement }) => {
  const environment: any = (domElement?.getAttribute('data-env') ||
    'development') as 'production' | 'development'

  const baseUrl = process.env.REACT_APP_ASTRO_BASE_URL || ''
  const appApiLegacyUrl = process.env.REACT_APP_ASTRO_APP_API_URL_LEGACY || ''
  const talkApiLegacyUrl = process.env.REACT_APP_ASTRO_TALK_API_URL_LEGACY || ''
  const cardBaseUrl = process.env.REACT_APP_ASTRO_CARD_BASE_URL || ''
  const talkUrl = process.env.REACT_APP_URI_API_TALK || ''

  const workUrl = process.env.REACT_APP_ASTRONAV_WORK_URL || ''
  const astroNavTalkUrl = process.env.REACT_APP_ASTRONAV_TALK_URL || ''
  const talkApiUrl =
    process.env.REACT_APP_ASTRONAV_TALK_API_URL ||
    process.env.REACT_APP_URI_API_TALK ||
    ''

  const secureCdnUrl = process.env.REACT_APP_URI_CDN_SECURE || ''
  const translationBaseUrl = process.env.REACT_APP_TRANSLATOR_BASE_URL || ''

  const fileBaseUrl = process.env.REACT_APP_FILE_BASE_URL || ''

  const astroNav = React.useMemo(
    () =>
      new AstroNav({
        workUrl,
        talkUrl: astroNavTalkUrl,
        talkApiUrl,
      }),
    [workUrl, talkUrl, talkApiUrl]
  )

  const astro = React.useMemo(
    () =>
      new Astro({
        baseUrl,
        talkUrl,
        appApiLegacyUrl,
        cardBaseUrl,
        userBaseUrl: appApiLegacyUrl,
        talkBaseUrl: talkApiLegacyUrl,
        talkApiLegacyUrl,
        workLegacyUrl: workUrl,
        secureCdnUrl,
        previewerBaseUrl: process.env.REACT_APP_PREVIEWER_URL,
        environment,
        authBaseUrl: process.env.REACT_APP_AUTH_API_URL,
        authKakaoAppKey: process.env.REACT_APP_KAKAO_APP_KEY,
        authNaverAppKey: process.env.REACT_APP_NAVER_APP_KEY,
        authFirebaseAppKey: process.env.REACT_APP_FIREBASE_APP_KEY,
        authFirebaseAppId: process.env.REACT_APP_FIREBASE_APP_ID,
        authFirebaseProjectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        authFirebaseSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
        translationBaseUrl,
        fileBaseUrl,
      }),
    [baseUrl, environment, appApiLegacyUrl]
  )

  console.log('astro', {
    baseUrl,
    talkUrl,
    appApiLegacyUrl,
    cardBaseUrl,
    userBaseUrl: appApiLegacyUrl,
    talkBaseUrl: talkApiLegacyUrl,
    talkApiLegacyUrl,
    workLegacyUrl: workUrl,
    secureCdnUrl,
    previewerBaseUrl: process.env.REACT_APP_PREVIEWER_URL,
    environment,
    authBaseUrl: process.env.REACT_APP_AUTH_API_URL,
    authKakaoAppKey: process.env.REACT_APP_KAKAO_APP_KEY,
    authNaverAppKey: process.env.REACT_APP_NAVER_APP_KEY,
    authFirebaseAppKey: process.env.REACT_APP_FIREBASE_APP_KEY,
    authFirebaseAppId: process.env.REACT_APP_FIREBASE_APP_ID,
    authFirebaseProjectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    authFirebaseSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    fileBaseUrl,
  })

  const loading = useRecoilValue(loadingState)

  const cardDeleted = useRecoilValue(cardDeletedState)

  const [height, setHeight] = React.useState('0px')

  const { pathname } = useLocation()
  const { push } = useHistory()

  const isMounted = useIsMounted()

  const setVhProp = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  React.useLayoutEffect(() => {
    if (!isMounted()) return

    window.addEventListener('load', setVhProp)

    return () => window.removeEventListener('load', setVhProp)
  }, [])

  React.useLayoutEffect(() => {
    if (!isMounted()) return

    window.addEventListener('resize', setVhProp)

    return () => window.removeEventListener('resize', setVhProp)
  }, [isMounted, setVhProp])

  // MARK: Astronaut SDK event interactions
  React.useEffect(() => {
    window.addEventListener('message', (message) => {
      var { action, payload } = message.data
      switch (action) {
        case 'openChat':
          astroNav.prepare({
            token: payload.apiToken,
          })
          astroNav.go('popup.chat.withHighlight', {
            roomId: payload.channelId,
            messageId: payload.messageId,
            cardNo: payload.cardNo,
          })
          break
        case 'openCard':
          // let popupRoute = 'popup.card.withHighlight'
          // if (
          //   payload.cardType &&
          //   (payload.cardType === 'ATTEND' || payload.cardType === 'MEETING')
          // ) {
          //   popupRoute = 'popup.schedule'
          // }
          // astroNav.prepare({
          //   token: payload.apiToken,
          // })
          // astroNav.go(popupRoute, {
          //   channelId: payload.channelId,
          //   cardId: payload.cardId,
          // })

          sessionStorage.setItem('sessionCardNo', payload.cardId)
          const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=400,height=800,left=100,top=100`
          let url = '/card'

          if (payload.cardType === 'TASKREPORT') {
            const tarkUrl = process.env.REACT_APP_APPS_TASKREPORT_URI
            if (tarkUrl)
              url =
                tarkUrl +
                `?apiToken=${payload.apiToken}&channelId=${payload.channelId}&cardNo=${payload.cardId}`
            else {
              return
            }
          } else {
            url =
              process.env.REACT_APP_APPS_CARD_URI +
              `/card/${payload.cardId}?channelId=${payload.channelId}`
          }
          window.open(url, 'popupView-' + payload.cardId, params)

          break
      }
    })
  }, [])

  React.useEffect(() => {
    if (isMounted()) {
      setHeight(() => window.innerHeight + 'px')
      window.scrollTo(0, document.body.scrollHeight)
    }
  }, [isMounted, pathname])

  const isTalkInputFocus = useRecoilValue(isTalkInputFocusState)

  React.useEffect(() => {
    const onKeyPress = (e: any) => {
      if (e.keyCode === 27 && !isTalkInputFocus) {
        window.close()
      }
    }
    document.addEventListener('keydown', onKeyPress)
    return () => {
      document.removeEventListener('keydown', onKeyPress)
    }
  }, [isTalkInputFocus])

  if (cardDeleted) {
    return <CardDeleted visible={cardDeleted} />
  }

  return (
    <div className="app" style={{ height }}>
      <Suspense fallback={<></>}>
        <I18nextProvider i18n={i18nInstance}>
          <BackdropProvider>
            <ModalDialog.Provider>
              <Suspense
                fallback={<IonLoading isOpen={true} cssClass="loading-class" />}
              >
                <IonLoading isOpen={loading} cssClass="loading-class" />
                <DataLoad pathname={pathname} push={push} astro={astro}>
                  <Subscription astro={astro}>
                    <IonRouterOutlet>
                      <Route exact path="/" component={MainContainer} />
                      <Route exact path="/card" component={CardContainer} />
                      <Route
                        exact
                        path="/card/mission"
                        component={CardMissionContainer}
                      />
                      <Route
                        exact
                        path="/card/mission/:token"
                        component={AuthContainer}
                      />
                      <Route
                        exact
                        path="/card/missionBrief"
                        component={CardMissionContainer}
                      />
                      <Route
                        exact
                        path="/card/missionBrief/:token"
                        component={AuthContainer}
                      />
                      <Route
                        exact
                        path="/card/attend"
                        component={CardAttendContainer}
                      />
                      <Route
                        exact
                        path="/card/attend/:token"
                        component={AuthContainer}
                      />
                      <Route
                        exact
                        path="/card/attendBrief"
                        component={CardAttendContainer}
                      />
                      <Route
                        exact
                        path="/card/attendBrief/:token"
                        component={AuthContainer}
                      />
                      <Route
                        exact
                        path="/card/collection"
                        component={CardGatheringContainer}
                      />
                      <Route
                        exact
                        path="/card/collection/:token"
                        component={AuthContainer}
                      />
                      <Route
                        exact
                        path="/card/collectionBrief"
                        component={CardGatheringContainer}
                      />
                      <Route
                        exact
                        path="/card/collectionBrief/:token"
                        component={AuthContainer}
                      />
                      <Route exact path="/chat" component={TalkContainer} />
                      <Route
                        exact
                        path="/chat/:token"
                        component={AuthContainer}
                      />
                      <Route
                        exact
                        path="/maintenance"
                        component={Maintenance}
                      />
                      <Route component={NoPage} />
                    </IonRouterOutlet>
                  </Subscription>
                </DataLoad>
              </Suspense>
            </ModalDialog.Provider>
          </BackdropProvider>
        </I18nextProvider>
      </Suspense>
    </div>
  )
}

export default App
