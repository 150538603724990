import { MemberType } from './user'

export enum RoomType {
  GROUP = 'G',
  INDIVIDUAL = 'D',
  DISCUSSION = 'S',
  TOME = 'M',
}

export type ChannelType = {
  no: number
  discussionRoom: boolean
  roomType?: RoomType
  roomId: string
  roomName: string
  roomDateTime: string
  closed: boolean
  members: MemberType[]
  allMembers?: MemberType[]
  projectNo?: number
  isNotice?: boolean
  noticeMessageNo?: string
  unreadCount: number
  groupTitle?: string
  projectElementNo?: number
}
